import "../fake-db";
import React, { Suspense } from "react";
import "../styles/app/app.scss";

import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import AppContext from "./appContext";
// import history from "@history";

import routes from "./RootRoutes";
import { history, configureStore } from "./redux/store";
import { renderRoutes } from "react-router-config";
// import Auth from "./auth/Auth";
import RootRoutes from "./RootRoutes";
import { PersistGate } from "redux-persist/integration/react";
import { Loading } from "@gull";
import Pages from "./pages";
import AlertView from "./Alert/AlertView";
export const { store, persistor } = configureStore();

function App() {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <Auth> */}
          <Suspense fallback={<Loading></Loading>}>
            {/* <Router history={history}>{renderRoutes(RootRoutes)}</Router> */}
            <Router history={history}>
              <Switch>
                {/* <Route exact path="/" component={Home} /> */}
                <Route path="/" component={Pages} />
              </Switch>
            </Router>
            <AlertView />
          </Suspense>
          {/* </Auth> */}
        </PersistGate>
      </Provider>
    </AppContext.Provider>
  );
}

export default App;

// 3 - Font should be https://fonts.google.com/specimen/Hind+Madurai?query=Hind+Madurai
// 4 - Primary color: #5E84C3
