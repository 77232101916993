import { lazy } from "react";

const LoginContainer = lazy(() => import("./LoginContainer"));

const loginRoutes = [
  {
    path: "/session/signin",
    component: LoginContainer,
  },
];

export default loginRoutes;
