import * as mainPageServices from "../../../services/mainPageService";
import * as types from "../../constants/actionTypes";

export function fetchBrandsList(search_data, page = 1, loader = true) {
  return (dispatch) => {
    if (loader === true) {
      dispatch({
        type: types.SHOW_ALERT,
        title: null,
        isOpen: true,
        msg: null,
        alertType: "loading",
      });
    }
    dispatch({
      type: types.SET_BRANDS_LIST_DATA,
      // brandsListData:
      //   getState().brandsManagementReducer.brandsListReducer.brandsListData,
      brandsListData: {},
    });

    mainPageServices.fetchBrandsList(
      //   first_name,
      //   last_name,
      //   role,
      //   organization,
      //   title,
      //   is_inactive,
      //   page,
      search_data,
      page,
      (response, error) => {
        if (error) {
          dispatch({
            type: types.SHOW_ALERT,
            title: "Error",
            isOpen: true,
            msg: error,
            alertType: "error",
          });
          dispatch({
            type: types.FETCH_BRANDS_LIST_API_ERROR,
            fetchBrandsListApiPending: false,
            fetchBrandsListApiSuccess: false,
            fetchBrandsListApiError: error,
            fetchBrandsListApiResponse: null,
          });
          return null;
        } else {
          if (loader === true) {
            dispatch({
              type: types.HIDE_ALERT,
              title: null,
              isOpen: false,
              msg: null,
              alertType: null,
            });
          }
          dispatch({
            type: types.FETCH_BRANDS_LIST_API_SUCCESS,
            fetchBrandsListApiPending: false,
            fetchBrandsListApiSuccess: true,
            fetchBrandsListApiError: null,
            fetchBrandsListApiResponse: true,
          });
          dispatch({
            type: types.SET_BRANDS_LIST_DATA,
            brandsListData: response.data.brands,
          });
        }
        return;
      }
    );
  };
}

export function setBrandStatus(code, val, search_data, page) {
  return (dispatch) => {
    mainPageServices.setBrandStatus(code, val, (response, error) => {
      if (error) {
        dispatch({
          type: types.SET_BRAND_STATUS_ERROR,
          setBrandStatusPending: false,
          setBrandStatusSuccess: false,
          setBrandStatusError: error,
          setBrandStatusResponse: null,
        });
        return null;
      } else {
        if (response.status == false) {
          dispatch({
            type: types.SET_BRAND_STATUS_ERROR,
            setBrandStatusPending: false,
            setBrandStatusSuccess: false,
            setBrandStatusError: response.message,
            setBrandStatusResponse: null,
          });
        } else {
          dispatch({
            type: types.SHOW_ALERT,
            title: "Success",
            isOpen: true,
            msg: val ? "Status Set To Inactive" : "Status Set To Active",
            alertType: "success",
          });
          dispatch({
            type: types.SET_BRAND_STATUS_SUCCESS,
            setBrandStatusPending: false,
            setBrandStatusSuccess: true,
            setBrandStatusError: null,
            setBrandStatusResponse: response,
          });
          // dispatch(fetchPIList("", !val));
          dispatch(fetchBrandsList(search_data, page, false));
        }
      }
      return;
    });
  };
}

export function fetchMedicinesList(search_data, page = 1, loader = true) {
  return (dispatch) => {
    if (loader === true) {
      dispatch({
        type: types.SHOW_ALERT,
        title: null,
        isOpen: true,
        msg: null,
        alertType: "loading",
      });
    }

    dispatch({
      type: types.SET_MEDICINES_LIST_DATA,
      // medicinesListData:
      //   getState().medicinesManagementReducer.medicinesListReducer.medicinesListData,
      medicinesListData: {},
    });

    mainPageServices.fetchMedicinesList(
      //   first_name,
      //   last_name,
      //   role,
      //   organization,
      //   title,
      //   is_inactive,
      //   page,
      search_data,
      page,
      (response, error) => {
        if (error) {
          dispatch({
            type: types.SHOW_ALERT,
            title: "Error",
            isOpen: true,
            msg: error,
            alertType: "error",
          });
          dispatch({
            type: types.FETCH_MEDICINES_LIST_API_ERROR,
            fetchMedicinesListApiPending: false,
            fetchMedicinesListApiSuccess: false,
            fetchMedicinesListApiError: error,
            fetchMedicinesListApiResponse: null,
          });
          return null;
        } else {
          if (loader === true) {
            dispatch({
              type: types.HIDE_ALERT,
              title: null,
              isOpen: false,
              msg: null,
              alertType: null,
            });
          }
          dispatch({
            type: types.FETCH_MEDICINES_LIST_API_SUCCESS,
            fetchMedicinesListApiPending: false,
            fetchMedicinesListApiSuccess: true,
            fetchMedicinesListApiError: null,
            fetchMedicinesListApiResponse: true,
          });
          dispatch({
            type: types.SET_MEDICINES_LIST_DATA,
            medicinesListData: response.data.medicines,
          });
        }
        return;
      }
    );
  };
}

export function setMedicineStatus(code, val, search_data, page) {
  return (dispatch) => {
    mainPageServices.setMedicineStatus(code, val, (response, error) => {
      if (error) {
        dispatch({
          type: types.SET_MEDICINE_STATUS_ERROR,
          setMedicineStatusPending: false,
          setMedicineStatusSuccess: false,
          setMedicineStatusError: error,
          setMedicineStatusResponse: null,
        });
        return null;
      } else {
        if (response.status == false) {
          dispatch({
            type: types.SET_MEDICINE_STATUS_ERROR,
            setMedicineStatusPending: false,
            setMedicineStatusSuccess: false,
            setMedicineStatusError: response.message,
            setMedicineStatusResponse: null,
          });
        } else {
          dispatch({
            type: types.SHOW_ALERT,
            title: "Success",
            isOpen: true,
            msg: val ? "Status Set To Inactive" : "Status Set To Active",
            alertType: "success",
          });
          dispatch({
            type: types.SET_MEDICINE_STATUS_SUCCESS,
            setMedicineStatusPending: false,
            setMedicineStatusSuccess: true,
            setMedicineStatusError: null,
            setMedicineStatusResponse: response,
          });

          dispatch(fetchMedicinesList(search_data, page, false));
        }
      }
      return;
    });
  };
}
