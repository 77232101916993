import {
  REQUEST_METHOD,
  REQUEST_URL,
  REQUEST_CONTENT_TYPE,
} from "./apiConstants";
import { getState } from "../redux/store";
import { fetchCall } from "../utils/commonUtility";

export function fetchBrandsList(search_data, page, callback) {
  var token = getState().userLoginReducer.accessToken;

  // let bodyString = { name: "AAHAR FOOD" };
  // let bodyString = {data};

  let data = {
    method: REQUEST_METHOD.POST,
    body: JSON.stringify(search_data),
    headers: {
      "Content-Type": REQUEST_CONTENT_TYPE.JSON,
      Accept: REQUEST_CONTENT_TYPE.JSON,
      //   Authorization: token,
      "x-auth-token-clepios": token,
    },
  };
  console.log(page);

  fetchCall(
    REQUEST_URL.BRANDS_LIST_URL + "?page=" + page + "&limit=10",
    data,
    callback
  );
}

export function setBrandStatus(code, val, callback) {
  var token = getState().userLoginReducer.accessToken;

  let bodyString = {
    active: !val,
  };

  let data = {
    method: REQUEST_METHOD.POST,
    body: JSON.stringify(bodyString),
    headers: {
      "Content-Type": REQUEST_CONTENT_TYPE.JSON,
      //   Authorization: token,
      "x-auth-token-clepios": token,
    },
  };

  fetchCall(REQUEST_URL.SET_BRAND_STATUS_URL + code, data, callback);
}

export function fetchMedicinesList(search_data, page, callback) {
  var token = getState().userLoginReducer.accessToken;

  // let bodyString = { name: "AAHAR FOOD" };
  // let bodyString = {data};

  let data = {
    method: REQUEST_METHOD.POST,
    body: JSON.stringify(search_data),
    headers: {
      "Content-Type": REQUEST_CONTENT_TYPE.JSON,
      Accept: REQUEST_CONTENT_TYPE.JSON,
      //   Authorization: token,
      "x-auth-token-clepios": token,
    },
  };
  console.log(page);

  fetchCall(
    REQUEST_URL.MEDICINES_LIST_URL + "?page=" + page + "&limit=10",
    data,
    callback
  );
}

export function setMedicineStatus(code, val, callback) {
  var token = getState().userLoginReducer.accessToken;

  let bodyString = {
    active: !val,
  };

  let data = {
    method: REQUEST_METHOD.POST,
    body: JSON.stringify(bodyString),
    headers: {
      "Content-Type": REQUEST_CONTENT_TYPE.JSON,
      //   Authorization: token,
      "x-auth-token-clepios": token,
    },
  };

  fetchCall(REQUEST_URL.SET_MEDICINE_STATUS_URL + code, data, callback);
}
