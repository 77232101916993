//LOGIN

export const LOGIN_PENDING = "LOGIN_PENDNG";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_RESET = "LOGIN_RESET";
export const SET_USER_DATA = "SET_USER_DATA";
export const RESET_USER_DATA = "RESET_USER_DATA";

export const RESET_ACCESS_TOKEN = "RESET_ACCESS_TOKEN";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

//ALERT

export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

//BRANDS

export const FETCH_BRANDS_LIST_API_PENDING = "FETCH_BRANDS_LIST_API_PENDING";
export const FETCH_BRANDS_LIST_API_SUCCESS = "FETCH_BRANDS_LIST_API_SUCCESS";
export const FETCH_BRANDS_LIST_API_ERROR = "FETCH_BRANDS_LIST_API_ERROR";
export const FETCH_BRANDS_LIST_API_RESET = "FETCH_BRANDS_LIST_API_RESET";

export const SET_BRANDS_LIST_DATA = "SET_BRANDS_LIST_DATA";
export const RESET_BRANDS_LIST_DATA = "RESET_BRANDS_LIST_DATA";

export const SET_BRAND_STATUS_ERROR = "SET_BRAND_STATUS_ERROR";
export const SET_BRAND_STATUS_SUCCESS = "SET_BRAND_STATUS_ERROR";
export const SET_BRAND_STATUS_PENDING = "SET_BRAND_STATUS_ERROR";
export const SET_BRAND_STATUS_RESET = "SET_BRAND_STATUS_RESET";

//MEDICINES

export const FETCH_MEDICINES_LIST_API_PENDING =
  "FETCH_MEDICINES_LIST_API_PENDING";
export const FETCH_MEDICINES_LIST_API_SUCCESS =
  "FETCH_MEDICINES_LIST_API_SUCCESS";
export const FETCH_MEDICINES_LIST_API_ERROR = "FETCH_MEDICINES_LIST_API_ERROR";
export const FETCH_MEDICINES_LIST_API_RESET = "FETCH_MEDICINES_LIST_API_RESET";

export const SET_MEDICINES_LIST_DATA = "SET_MEDICINES_LIST_DATA";
export const RESET_MEDICINES_LIST_DATA = "RESET_MEDICINES_LIST_DATA";

export const SET_MEDICINE_STATUS_ERROR = "SET_MEDICINE_STATUS_ERROR";
export const SET_MEDICINE_STATUS_SUCCESS = "SET_MEDICINE_STATUS_ERROR";
export const SET_MEDICINE_STATUS_PENDING = "SET_MEDICINE_STATUS_ERROR";
export const SET_MEDICINE_STATUS_RESET = "SET_MEDICINE_STATUS_RESET";
