// import * as commonUtility from "../utils/commonUtility";
import { store } from "../redux/store";
import history from "@history.js";
import { logout } from "../redux/actions/Login/LoginActions";

export function fetchCall(URL, data, callback) {
  fetch(URL, data)
    .then((response) => Promise.all([response.status, response.json()]))
    .then(([status, responseBody]) => {
      if (status !== 200 && status !== 201) {
        if (status === 401) {
          history.push("/login");
          // logout();
          store.dispatch(logout());
          // return callback(null, "Session Expired")
        } else {
          throw responseBody;
        }
      } else {
        return callback(responseBody, null);
      }
    })
    .catch((error) => {
      var message = "";
      //   message = commonUtility.sendError(error.msg);
      return callback(null, message);
    });
}

export function sendError(error) {
  var message = "";
  if (typeof error == "object") {
    var message2 = "";
    message2 = error[Object.keys(error)[0]];
    if (message2.length > 1) {
      message = message2.join(". ");
    } else {
      message = message2;
    }
  } else if (typeof error == "string") {
    message = error;
  } else {
    message = "";
  }
  return message;
}

export function loginValidation(email, password) {
  var error_message = null;
  if (email == null || email == "") {
    error_message = "Email Is Empty";
  } else if (password == null || password == "") {
    error_message = "Password Is Empty";
  } else if (/\S+@\S+\.\S+/.test(email) == false) {
    error_message = "Email Format Invalid";
  } else if (password.length < 8) {
    error_message = "Password Should Contain 8 or more characters";
  }

  return error_message;
}
