import * as types from "../../constants/actionTypes";

function brandsStatusUpdateReducer(
  state = {
    setBrandStatusPending: false,
    setBrandStatusSuccess: false,
    setBrandStatusError: null,
    setBrandStatusResponse: null,
  },
  action
) {
  switch (action.type) {
    case types.SET_BRAND_STATUS_PENDING:
    case types.SET_BRAND_STATUS_SUCCESS:
    case types.SET_BRAND_STATUS_ERROR:
    case types.SET_BRAND_STATUS_RESET:
      return Object.assign({}, state, {
        setBrandStatusPending: action.setBrandStatusPending,
        setBrandStatusSuccess: action.setBrandStatusSuccess,
        setBrandStatusError: action.setBrandStatusError,
        setBrandStatusResponse: action.setBrandStatusResponse,
      });

    default:
      return state;
  }
}

export default brandsStatusUpdateReducer;
