import * as types from "../constants/actionTypes";

export function hideAlert() {
  return (dispatch) => {
    dispatch({
      type: types.HIDE_ALERT,
      title: null,
      isOpen: false,
      msg: null,
    });
    return;
  };
}

export function showAlert(title, message) {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_ALERT,
      title: "show",
      isOpen: true,
      msg: "show",
    });
    return;
  };
}
