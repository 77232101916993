import ListWidget from "app/views/widgets/ListWidget";
import React from "react";

import { Redirect, Route, Switch } from "react-router-dom";
import { getState, store } from "../../redux/store";
import Brands from "./brands/Brands";
import Medicines from "./medicines/Medicines";

const Modules = ({ match }) => {
  var defaultRedirection = getState().userLoginReducer.userData ? (
    <Redirect exact from={`${match.url}/`} to={`${match.url}/brands`} />
  ) : (
    ""
  );

  //   var roleBasedRoute = [];
  //   if (getState().userLoginReducer.userData) {
  //     // if (isFirstLoad) {
  //     //   isFirstLoad = false;

  //     // }

  //     if (
  //       getState().userLoginReducer.userData.user_role_id == ORGANIZATION_USER

  //     ) {
  //       roleBasedRoute.push(
  //         <Route
  //           path={
  //             `${match.url}/organizations/edit-organization/` +
  //             getState().userLoginReducer.userData.organization.organization_id
  //           }
  //           component={OrganizationDetailsContainer}
  //         />
  //       );
  //     }
  //   }
  //   else {
  //     store.dispatch(logout());
  //   }

  return (
    <Switch>
      {defaultRedirection}
      {/* {roleBasedRoute} */}
      {/* <Route path={`${match.url}/brands`} component={ListWidget} /> */}
      <Route path={`${match.url}/brands`} component={Brands} />
      <Route path={`${match.url}/medicines`} component={Medicines} />

      <Redirect exact from={`${match.url}/*`} to={`${match.url}`} />
    </Switch>
  );
};

export default Modules;
