import React, { Component, Suspense } from "react";
import Layout1Sidenav from "./Layout1/Layout1Sidenav";

import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import Footer from "./SharedComponents/Footer";
import { classList } from "@utils";
import { getState } from "./../redux/store";
import GullSearch from "@gull/components/GullSearch";
import { merge } from "lodash";
import Loading from "@gull/components/GullLoadable/Loading";
import Layout1Header from "./Layout1/Layout1Header";

class MainLayout extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }
  state = {};

  handleSearchBoxClose = () => {
    let { setLayoutSettings, settings } = this.props;
    setLayoutSettings(
      merge({}, settings, {
        layout1Settings: {
          searchBox: {
            open: false,
          },
        },
      })
    );
  };

  render() {
    let { settings, routes } = this.props;

    return (
      <Suspense>
        <React.Fragment>
          <div>
            <div className={`app-admin-wrap layout-sidebar-large`}>
              <Layout1Header></Layout1Header>
              <Layout1Sidenav />
              {/* sidebar */}

              <div
                className={classList({
                  "main-content-wrap d-flex flex-column": true,
                  "sidenav-open": settings.layout1Settings.leftSidebar.open,
                })}
              >
                <Suspense fallback={<Loading />}>
                  <div className="main-content">
                    <main>{this.props.children}</main>
                  </div>
                </Suspense>
                {/* {settings.footer.show && <Footer></Footer>} */}
              </div>
            </div>
            <GullSearch
              //   open={settings.layout1Settings.searchBox.open}
              handleClose={this.handleSearchBoxClose}
            ></GullSearch>
          </div>
          {/* {settings.customizer.show && <Customizer></Customizer>} */}
        </React.Fragment>
      </Suspense>
    );
  }
}

MainLayout.propTypes = {
  children: PropTypes.object.isRequired,
  getState: PropTypes.func.isRequired,
  stateLoaded: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  setLayoutSettings: PropTypes.func.isRequired,
  settings: state.layout.settings,
});

export default connect(mapStateToProps)(MainLayout);
