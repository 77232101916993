import * as types from "../../constants/actionTypes";

function medicinesStatusUpdateReducer(
  state = {
    setMedicineStatusPending: false,
    setMedicineStatusSuccess: false,
    setMedicineStatusError: null,
    setMedicineStatusResponse: null,
  },
  action
) {
  switch (action.type) {
    case types.SET_MEDICINE_STATUS_PENDING:
    case types.SET_MEDICINE_STATUS_SUCCESS:
    case types.SET_MEDICINE_STATUS_ERROR:
    case types.SET_MEDICINE_STATUS_RESET:
      return Object.assign({}, state, {
        setMedicineStatusPending: action.setMedicineStatusPending,
        setMedicineStatusSuccess: action.setMedicineStatusSuccess,
        setMedicineStatusError: action.setMedicineStatusError,
        setMedicineStatusResponse: action.setMedicineStatusResponse,
      });

    default:
      return state;
  }
}

export default medicinesStatusUpdateReducer;
