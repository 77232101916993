import * as types from "../../constants/actionTypes";

function medicinesListReducer(
  state = {
    fetchMedicinesListApiPending: false,
    fetchMedicinesListApiSuccess: false,
    fetchMedicinesListApiError: null,
    fetchMedicinesListApiResponse: null,
    medicinesListData: {},
  },
  action
) {
  switch (action.type) {
    case types.FETCH_MEDICINES_LIST_API_PENDING:
    case types.FETCH_MEDICINES_LIST_API_SUCCESS:
    case types.FETCH_MEDICINES_LIST_API_ERROR:
    case types.FETCH_MEDICINES_LIST_API_RESET:
      return Object.assign({}, state, {
        fetchMedicinesListApiPending: action.fetchMedicinesListApiPending,
        fetchMedicinesListApiSuccess: action.fetchMedicinesListApiSuccess,
        fetchMedicinesListApiError: action.fetchMedicinesListApiError,
        fetchMedicinesListApiResponse: action.fetchMedicinesListApiResponse,
      });
    case types.SET_MEDICINES_LIST_DATA:
      return Object.assign({}, state, {
        medicinesListData: action.medicinesListData,
      });
    default:
      return state;
  }
}

export default medicinesListReducer;
