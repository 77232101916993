import { combineReducers } from "redux";
import userLoginReducer from "./userLoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import brandsReducer from "./brandsReducer";
import medicinesReducer from "./medicinesReducer";
import alertReducer from "./alertReducer";

const reducer = combineReducers({
  alert: alertReducer,
  userLoginReducer,
  brandsReducer,
  medicinesReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
});

export default reducer;
