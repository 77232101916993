import { combineReducers } from "redux";
import brandsListReducer from "./brandsListReducer";
import brandsStatusUpdateReducer from "./brandsStatusUpdateReducer";

const brandsReducer = combineReducers({
  brandsListReducer,
  brandsStatusUpdateReducer,
});

export default brandsReducer;
