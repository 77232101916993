import * as types from "../constants/actionTypes";

export default function alertReducer(
  state = {
    isOpen: false,
    title: "",
    type: "",
    msg: "",
    type: "",
  },
  action
) {
  switch (action.type) {
    case types.SHOW_ALERT:
    case types.HIDE_ALERT:
      return Object.assign({}, state, {
        isOpen: action.isOpen,
        type: action.alertType,
        title: action.title,
        msg: action.msg,
      });

    default:
      return state;
  }
}
