import history from "@history.js";
import * as types from "../../constants/actionTypes";

import { getState } from "../../store";
import * as loginServices from "../../../services/Login/LoginService";
import { loginValidation } from "app/utils/commonUtility";

export function login(param1, param2) {
  return (dispatch) => {
    if (loginValidation(param1, param2) == null) {
      dispatch({
        type: types.SHOW_ALERT,
        title: null,
        isOpen: true,
        msg: null,
        alertType: "loading",
      });
      dispatch({
        type: types.LOGIN_PENDING,
        loginPending: true,
        loginSuccess: false,
        loginError: null,
        loginResponse: null,
      });

      loginServices.requestLogin(param1, param2, (response, headers, error) => {
        if (error) {
          dispatch({
            type: types.SHOW_ALERT,
            title: "Error",
            isOpen: true,
            msg: "Invalid Credentials",
            // msg: error,
            alertType: "error",
          });
          dispatch({
            type: types.LOGIN_ERROR,
            loginPending: false,
            loginSuccess: false,
            loginError: error,
            loginResponse: null,
          });
          return null;
        } else {
          var authToken = null;
          for (var pair of headers.entries()) {
            if (pair[0] === "x-auth-token-clepios") {
              authToken = pair[1];
            }
          }

          dispatch({
            type: types.SET_ACCESS_TOKEN,
            accessToken: authToken,
          });

          // dispatch({
          //   type: types.SET_ACCESS_TOKEN,
          //   accessToken: response.data,
          // });

          dispatch({
            type: types.SET_USER_DATA,
            userData: response.data,
          });

          dispatch({
            type: types.HIDE_ALERT,
            title: null,
            isOpen: false,
            msg: null,
            alertType: null,
          });

          dispatch({
            type: types.LOGIN_SUCCESS,
            loginPending: false,
            loginSuccess: true,
            loginError: null,
            loginResponse: response,
          });
        }
        return;
      });
    } else {
      dispatch({
        type: types.SHOW_ALERT,
        title: "Error",
        isOpen: true,
        msg: loginValidation(param1, param2),
        // msg: error,
        alertType: "error",
      });
    }
  };
}

export function logout() {
  return (dispatch) => {
    dispatch({
      type: types.HIDE_ALERT,
      title: null,
      isOpen: false,
      msg: null,
      alertType: null,
    });
    dispatch({
      type: types.LOGIN_PENDING,
      loginPending: false,
      loginSuccess: false,
      loginError: null,
      loginResponse: null,
    });
    dispatch({
      type: types.SET_ACCESS_TOKEN,
      accessToken: null,
    });

    // history.push("/session/signin");
    return null;
  };
}
