import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import { connect } from "react-redux";
import { login } from "../../redux/actions/Login/LoginActions";
import { MAIN_PATH } from "../../services/routes";
import { Redirect } from "react-router-dom";

import * as yup from "yup";
import { Formik } from "formik";

import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import auth from "../../restricted/auth";
const SigninSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("email is required"),
  password: yup
    .string()
    .min(8, "Password must be 8 character long")
    .required("password is required"),
});
export class LoginContainer extends Component {
  state = {
    email: "",
    password: "",
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  onLoginSuccess = () => {
    this.props.login(this.state.email, this.state.password);
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };
  handlePwdChange = (e) => {
    this.setState({ password: e.target.value });
  };

  onLoginError = (response) => {
    // console.log(response);
  };

  render() {
    if (auth.isAuthenticated()) {
      return <Redirect to="/pages/brands" />;
      // return <Redirect to={MAIN_PATH} />;
    }
    return (
      <div
        className="auth-layout-wrap"
        style={{
          backgroundImage: "url(/assets/images/loginBG1.jpg)",
          // backgroundImage: "url(/assets/images/photo-wide-4.jpg)",
        }}
      >
        <div className="auth-content">
          <div className="card o-hidden">
            <div className="row">
              <div>
                <div className="p-4">
                  <div className="auth-logo text-center mb-4">
                    <img src="/assets/images/logo.png" alt="" />
                  </div>
                  <h1 className="mb-3 text-18">Sign In</h1>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        onChange={(e) => this.handleEmailChange(e)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => this.handlePwdChange(e)}
                      />
                    </Form.Group>
                    <div>
                      <Button
                        className="btn btn-rounded btn-primary w-100 my-1 mt-2 text-white"
                        onClick={() => this.onLoginSuccess()}
                      >
                        LOGIN
                      </Button>
                    </div>
                  </Form>

                  <div className="mt-3 text-center">
                    <Link to="/session/forgot-password" className="text-muted">
                      <u>Forgot Password?</u>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                // className="col-md-6 text-center "
                style={
                  {
                    // backgroundSize: "cover",
                    // backgroundImage: "url(/assets/images/photo-long-3.jpg)",
                  }
                }
              >
                {/* <div className="pe-3 auth-right">
                  <Link
                    to="/session/signup"
                    className="btn btn-rounded btn-outline-primary btn-outline-email w-100 my-1 btn-icon-text"
                  >
                    <i className="i-Mail-with-At-Sign"></i> Sign up with Email
                  </Link>

                  <Button className="btn btn-rounded btn-outline-google w-100 my-1 btn-icon-text">
                    <i className="i-Google-Plus"></i> Sign up with Google
                  </Button>
                  <Button className="btn btn-rounded w-100 my-1 btn-icon-text btn-outline-facebook">
                    <i className="i-Facebook-2"></i> Sign up with Facebook
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginContainer.propTypes = {
  // login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accessToken: state.userLoginReducer.accessToken,
  loginSuccess: state.userLoginReducer.loginSuccess,
  // loginError: state.userLoginReducer.loginError,
  // loginResponse: state.userLoginReducer.loginResponse,
});

export default connect(mapStateToProps, {
  login,
})(LoginContainer);
