import { combineReducers } from "redux";
import medicinesListReducer from "./medicinesListReducer";
import medicinesStatusUpdateReducer from "./medicinesStatusUpdateReducer";

const medicinesReducer = combineReducers({
  medicinesListReducer,
  medicinesStatusUpdateReducer,
});

export default medicinesReducer;
