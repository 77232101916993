import * as types from "../constants/actionTypes";

export default function userLoginReducer(
  state = {
    loginPending: false,
    loginSuccess: false,
    loginError: null,
    loginResponse: null,
    accessToken: null,
    userData: null,
  },
  action
) {
  switch (action.type) {
    case types.LOGIN_PENDING:
    case types.LOGIN_SUCCESS:
    case types.LOGIN_ERROR:
    case types.LOGIN_RESET:
      return Object.assign({}, state, {
        loginPending: action.loginPending,
        loginSuccess: action.loginSuccess,
        loginError: action.loginError,
        loginResponse: action.loginResponse,
      });

    case types.SET_ACCESS_TOKEN:
    case types.RESET_ACCESS_TOKEN:
      return Object.assign({}, state, {
        accessToken: action.accessToken,
      });

    // case types.MY_ACCOUNT_API_ERROR:
    // case types.MY_ACCOUNT_API_SUCCESS:
    case types.SET_USER_DATA:
    case types.RESET_USER_DATA:
      return Object.assign({}, state, {
        userData: action.userData,
      });

    default:
      return state;
  }
}
