import { connect } from "react-redux";
import { Dropdown, Col, Row } from "react-bootstrap";
import React, { Component } from "react";
import {
  fetchBrandsList,
  setBrandStatus,
} from "../../../redux/actions/mainPageActions";
import { SimpleCard } from "@gull";

import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import styles from "./brands.scss";
class Brands extends Component {
  state = {
    page: 1,
    fields: {
      code: null,
      brand_name: null,
      active: true,
      limit: 10,
    },
    dataTablePage: 0,
    dataTablePerPage: 4,
    tableData: [
      {
        imgUrl: "/assets/images/faces/1.jpg",
        color: "success",
      },
      {
        imgUrl: "/assets/images/faces/2.jpg",
        color: "primary",
      },
      {
        imgUrl: "/assets/images/faces/3.jpg",
        color: "warning",
      },
      {
        imgUrl: "/assets/images/faces/4.jpg",
        color: "danger",
      },
      {
        imgUrl: "/assets/images/faces/5.jpg",
        color: "success",
      },
      {
        imgUrl: "/assets/images/faces/2.jpg",
        color: "primary",
      },
      {
        imgUrl: "/assets/images/faces/3.jpg",
        color: "warning",
      },
      {
        imgUrl: "/assets/images/faces/4.jpg",
        color: "danger",
      },
      {
        imgUrl: "/assets/images/faces/5.jpg",
        color: "success",
      },
    ],
  };

  componentDidUpdate() {}

  onChange = (field) => (val) => {
    let field_value = null;
    if (val != null) {
      let value = val.target
        ? val.target.type === "checkbox"
          ? val.target.checked
          : val.target.value
        : val;
      field_value = value;
    }

    this.setState({
      fields: {
        ...this.state.fields,
        [field]: field_value,
      },
    });
  };

  handleSubmit = () => {};
  handleRefresh = () => {};
  handleSearch = () => {
    var data = {
      code: [this.state.fields.code],
      name: this.state.fields.brand_name,
      // active: this.state.fields.active,
    };
    this.props.fetchBrandsList(data, this.state.page);
  };
  handlePageChange = (pageIp) => {
    var page = pageIp.selected + 1;
    this.setState({ page: page });
    var data = {
      code: [this.state.fields.code],
      name: this.state.fields.brand_name,
      // active: this.state.fields.active,
    };
    this.props.fetchBrandsList(data, page);
    // alert(JSON.stringify(page));
  };

  handleReset = () => {
    this.setState({
      fields: {
        code: "",
        brand_name: "",
        // active: true,
      },
      page: 1,
    });
    this.props.fetchBrandsList();
  };

  statusUpdate = (code, name, active) => {
    var search_data = {
      code: [this.state.fields.code],
      name: this.state.fields.brand_name,
    };
    this.props.setBrandStatus(code, active, search_data, this.state.page);
  };
  componentDidMount() {
    this.props.fetchBrandsList();
  }

  getUserStatusClass = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "inactive":
        return "badge-warning";
      case "pending":
        return "badge-primary";
      default:
        break;
    }
  };

  render() {
    let { tableData, dataTablePage, dataTablePerPage } = this.state;

    var data = this.props.brandsListData
      ? this.props.brandsListData.docs
        ? this.props.brandsListData.docs
        : null
      : null;
    var dataLength = this.props.brandsListData.docs
      ? this.props.brandsListData.docs.length
      : 0;

    var list = data
      ? data.map(({ code, name, active }) => (
          <tr>
            <td>{code}</td>
            <td>{name}</td>
            <td>
              {active && (
                <span
                  className={`badge badge-pill badge-outline-${"success"} p-2 m-1`}
                >
                  Active
                </span>
              )}

              {!active && (
                <span
                  className={`badge badge-pill badge-outline-${"danger"} p-2 m-1`}
                >
                  Inactive
                </span>
              )}
            </td>

            <td>
              <Dropdown>
                <Dropdown.Toggle
                  as="span"
                  className="toggle-hidden cursor-pointer"
                >
                  <span className="me-2"></span>

                  <span className="_dot _inline-dot bg-primary mr-1"></span>
                  <span> </span>
                  <span className="_dot _inline-dot bg-primary mr-1"></span>
                  <span> </span>

                  <span className="_dot _inline-dot bg-primary"></span>
                  <span> </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {active && (
                    <Dropdown.Item
                      onClick={() => this.statusUpdate(code, name, active)}
                    >
                      Set As Inactive
                    </Dropdown.Item>
                  )}
                  {!active && (
                    <Dropdown.Item
                      onClick={() => this.statusUpdate(code, name, active)}
                    >
                      Set As Active
                    </Dropdown.Item>
                  )}
                  {/* <Dropdown.Item>Another action</Dropdown.Item>
                  <Dropdown.Item>Something else here</Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item>Separated link</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        ))
      : null;

    return (
      <div>
        <SimpleCard title="Brands">
          <Row>
            <Col>
              <div className="ul-widget__head border-0 mb-2">
                <div className="ul-widget__head-label"></div>
                <div className="d-flex justify-content-end align-items-center">
                  <span className="me-1">Search:</span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Brand Code"
                    value={this.state.fields.code}
                    onChange={this.onChange("code")}
                    // multiple
                  />

                  <span className="me-1"></span>

                  <input
                    type="text"
                    class="form-control"
                    placeholder="Brand"
                    onChange={this.onChange("brand_name")}
                    value={this.state.fields.brand_name}
                  />
                  <span className="me-2"></span>

                  <button
                    className={`btn btn-outline-${"primary"} m-1 `}
                    // className="search_button"
                    type="button"
                    onClick={() => this.handleSearch()}
                  >
                    Search
                  </button>
                  {/* <button type="button" className="btn btn-outline-danger m-1">
                    Follow
                  </button> */}
                  <span className="me-1"></span>

                  <button
                    className="btn btn-outline-danger "
                    type="button"
                    onClick={() => this.handleReset()}
                  >
                    Reset
                  </button>
                </div>

                {/* <Dropdown>
                    <Dropdown.Toggle
                      as="span"
                      className="toggle-hidden cursor-pointer"
                    >
                      <span className="_dot _inline-dot bg-primary mr-1"></span>
                      <span> </span>

                      <span className="_dot _inline-dot bg-primary mr-1"></span>
                      <span> </span>

                      <span className="_dot _inline-dot bg-primary"></span>
                      <span> </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>Action</Dropdown.Item>
                      <Dropdown.Item>Another action</Dropdown.Item>
                      <Dropdown.Item>Something else here</Dropdown.Item>
                      <div className="dropdown-divider"></div>
                      <Dropdown.Item>Separated link</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
              </div>

              <div className="ul-widget-body">
                <div className="ul-widget3">
                  <div className="ul-widget6__item--table">
                    <table className="table ">
                      <thead>
                        <tr className="ul-widget6__tr--sticky-th">
                          <th scope="col">Code</th>

                          <th scope="col">Brand</th>

                          <th scope="col">Status</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>{list}</tbody>
                    </table>
                  </div>

                  <div className="card-footer text-muted">
                    <div className="d-flex flex-wrap justify-content-between align-items-center py-1">
                      <span>
                        Showing {this.props.brandsListData.pagingCounter} to{" "}
                        {this.props.brandsListData.pagingCounter +
                          dataLength -
                          1}{" "}
                        of {this.props.brandsListData.totalDocs} entries
                      </span>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.props.brandsListData.totalPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={this.handlePageChange}
                        containerClassName={"pagination pagination-lg"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.page - 1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </SimpleCard>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessToken: state.userLoginReducer.accessToken,
  brandsListData: state.brandsReducer.brandsListReducer.brandsListData,
});

export default connect(mapStateToProps, {
  fetchBrandsList,
  setBrandStatus,
})(Brands);
