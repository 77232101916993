import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Modules from "./routes";
import RestrictedRoute from "../restricted";

import LoginContainer from "./login/LoginContainer";

function Pages({ match }) {
  var defaultRedirection = (
    <Redirect exact from={`${match.url}/`} to={`${match.url}pages`} />
  );

  return (
    <Switch>
      {defaultRedirection}
      <Route exact path="/login" component={LoginContainer} />
      <RestrictedRoute path={`${match.url}pages`} component={Modules} />
    </Switch>
  );
}

export default Pages;
