// import thunk from "redux-thunk";
// import { createStore, applyMiddleware, compose } from "redux";
// import RootReducer from "./reducers/RootReducer";

// const initialState = {};

// const middlewares = [thunk];
// let devtools = (x) => x;

// if (
//   process.env.NODE_ENV !== "production" &&
//   process.browser &&
//   window.__REDUX_DEVTOOLS_EXTENSION__
// ) {
//   devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
// }

// export const store = createStore(
//   RootReducer,
//   initialState,
//   compose(applyMiddleware(...middlewares), devtools)
// );

// export const getState = () => {
//   return store.getState();
// };

import { applyMiddleware, createStore, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import storage from "redux-persist-indexeddb-storage";
import reducer from "./reducers/reducer";

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const persistConfig = {
  key: "root",
  storage: storage("gcDB"),
};
const persistedReducer = persistReducer(persistConfig, reducer);
const createHistory = require("history").createBrowserHistory;
const history = createHistory();

const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, routeMiddleware];

export const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);
// alert(JSON.stringify(store));
export const getState = () => {
  return store.getState();
};
export function configureStore(initialState) {
  if (module.hot) {
    // Enable Webpack hot module repylacement for reducers
    module.hot.accept("./reducers/reducer", () => {
      const nextRootReducer = require("./reducers/reducer");
      store.replaceReducer(nextRootReducer);
    });
  }

  let persistor = persistStore(store);
  //   console.log(persistor);
  //   console.log(store);
  return { store, persistor };
}
export { history };
