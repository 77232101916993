import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import uiKitsRoutes from "./views/ui-kits/uiKitsRoutes";
import formsRoutes from "./views/forms/formsRoutes";
import sessionsRoutes from "./pages/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import widgetsRoute from "./views/widgets/widgetsRoute";
import chartsRoute from "./views/charts/chartsRoute";
import dataTableRoute from "./views/dataTable/dataTableRoute";
import extraKitsRoutes from "./views/extra-kits/extraKitsRoutes";
import pagesRoutes from "./views/pages/pagesRoutes";
import iconsRoutes from "./views/icons/iconsRoutes";
import invoiceRoutes from "./views/app/invoice/invoiceRoutes";
import inboxRoutes from "./views/app/inbox/inboxRoutes";
import chatRoutes from "./views/app/chat/chatRoutes";
import calendarRoutes from "./views/app/calendar/calendarRoutes";
import taskManagerRoutes from "./views/app/task-manager/taskManagerRoutes";
import ecommerceRoutes from "./views/app/ecommerce/ecommerceRoutes";
import contactRoutes from "./views/app/contact/contactRoutes";
import loginRoutes from "./pages/login/loginRoutes";
import allRoutes from "./pages/routes/allroutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/pages/brands" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/signin" />,
    // component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...loginRoutes,
  // ...sessionsRoutes,
  {
    path: "/",
    component: AuthGuard,
    routes: [
      ...dashboardRoutes,
      // ...uiKitsRoutes,
      // ...formsRoutes,
      // ...widgetsRoute,
      // ...chartsRoute,
      // ...dataTableRoute,
      // ...extraKitsRoutes,
      // ...pagesRoutes,
      // ...iconsRoutes,
      // ...invoiceRoutes,
      // ...inboxRoutes,
      // ...chatRoutes,
      // ...taskManagerRoutes,
      // ...calendarRoutes,
      // ...ecommerceRoutes,
      // ...contactRoutes,
      ...allRoutes,
      ...redirectRoute,
      ...errorRoute,
    ],
  },
];

export default routes;
