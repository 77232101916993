import {
  REQUEST_METHOD,
  REQUEST_CONTENT_TYPE,
  REQUEST_URL,
} from "../apiConstants";
//Login API Request
export function requestLogin(param1, param2, callback) {
  let bodyString = {
    credentials: {
      email: param1,
      password: param2,
    },
  };

  let data = {
    method: REQUEST_METHOD.PATCH,
    body: JSON.stringify(bodyString),
    headers: {
      "Content-Type": REQUEST_CONTENT_TYPE.JSON,
    },
  };

  fetch(REQUEST_URL.SIGN_IN_URL, data)
    .then((response) => {
      // alert('RESPONSE :' + url + '\n' + JSON.stringify(response));
      console.log(response.headers);
      return Promise.all([response.status, response.headers, response.json()]);
    })
    .then(([status, headers, responseBody]) => {
      // console.log(
      //   'RESPONSE BODY:' + url + '\n\n' + JSON.stringify(responseBody),
      // );
      // if (responseBody.rid == 6017194 || responseBody.rid == 6021200) {
      //   Emitter.emit("token-expired");
      // }

      if (status != 200 && status != 201) {
        return callback(null, null, responseBody.msg);
      } else {
        return callback(responseBody, headers, null);
      }
    })
    .catch((error) => {
      var message = "Error occurred";

      // alert(JSON.stringify(error));
      if (error.message) {
        message = error.message;
      } else if (error) {
        message = error;
      }
      return callback(null, null, message);
    });
}
