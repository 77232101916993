import * as types from "../../constants/actionTypes";

function brandsListReducer(
  state = {
    fetchBrandsListApiPending: false,
    fetchBrandsListApiSuccess: false,
    fetchBrandsListApiError: null,
    fetchBrandsListApiResponse: null,
    brandsListData: {},
  },
  action
) {
  switch (action.type) {
    case types.FETCH_BRANDS_LIST_API_PENDING:
    case types.FETCH_BRANDS_LIST_API_SUCCESS:
    case types.FETCH_BRANDS_LIST_API_ERROR:
    case types.FETCH_BRANDS_LIST_API_RESET:
      return Object.assign({}, state, {
        fetchBrandsListApiPending: action.fetchBrandsListApiPending,
        fetchBrandsListApiSuccess: action.fetchBrandsListApiSuccess,
        fetchBrandsListApiError: action.fetchBrandsListApiError,
        fetchBrandsListApiResponse: action.fetchBrandsListApiResponse,
      });
    case types.SET_BRANDS_LIST_DATA:
      return Object.assign({}, state, {
        brandsListData: action.brandsListData,
      });
    default:
      return state;
  }
}

export default brandsListReducer;
