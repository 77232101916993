import { DOMAIN } from "./env";

export const APP_KEY = "test_key";

export const REQUEST_METHOD = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
};
export const REQUEST_CONTENT_TYPE = {
  JSON: "application/json",
};
export const REQUEST_PATH = {
  //LOGIN
  SIGN_IN_PATH: "auth/profile/signIn",

  //BRANDS CALL
  BRANDS_LIST_PATH: "util/master/brands",
  SET_BRAND_STATUS_PATH: "util/master/brands/update/",

  //MEDICINES

  MEDICINES_LIST_PATH: "util/master/medicines",
  SET_MEDICINE_STATUS_PATH: "util/master/medicines/update/",
};

export const REQUEST_URL = {
  //LOGIN
  SIGN_IN_URL: DOMAIN + REQUEST_PATH.SIGN_IN_PATH,

  //BRANDS CALL
  BRANDS_LIST_URL: DOMAIN + REQUEST_PATH.BRANDS_LIST_PATH,
  SET_BRAND_STATUS_URL: DOMAIN + REQUEST_PATH.SET_BRAND_STATUS_PATH,

  MEDICINES_LIST_URL: DOMAIN + REQUEST_PATH.MEDICINES_LIST_PATH,
  SET_MEDICINE_STATUS_URL: DOMAIN + REQUEST_PATH.SET_MEDICINE_STATUS_PATH,

  //MEDICINES
};
