import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import auth from "./auth";
import connect from "react-redux/es/connect/connect";
// import MainLayout from "../MainLayout/MainLayout";
import MainLayout from "../GullLayout/MainLayout";
import { string } from "prop-types";

const RestrictedRoute = ({ component: Component, ...rest }) => {
  var str = window.location;
  var url = new URL(str);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isAuthenticated()) {
          return (
            <MainLayout>
              <Component {...props} />
            </MainLayout>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                  original_path: url.pathname,
                  // message: "Please log in first",
                },
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps)(RestrictedRoute));

// export default RestrictedRoute;
