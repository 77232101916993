import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { hideAlert } from "../redux/actions/alertActions";
import { bindActionCreators } from "redux";
// import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlert from "sweetalert2-react";
import swal from "sweetalert2";
import { Modal } from "bootstrap";
// import Logo from "../../assets/loader-waiting.gif";
import { Loading } from "@gull";

class AlertView extends PureComponent {
  onConfirm = () => {
    this.props.hideAlert();
  };
  toggleAlert = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  closeAlert = (name) => {
    this.setState({ [name]: false });
  };
  render() {
    const { isOpen, title, msg, type, hideAlert } = this.props;
    var alert = "";
    if (type === "error") {
      alert = (
        <SweetAlert
          show={true}
          title={title}
          text={msg}
          onConfirm={() => this.onConfirm()}
          type="error"
        />
      );
    } else if (type === "success") {
      alert = (
        <SweetAlert
          show={true}
          title={title}
          text={msg}
          type="success"
          onConfirm={() => this.onConfirm()}
        />
      );
    } else if (type === "loading") {
      alert = <Loading></Loading>;
    }

    return <div>{alert}</div>;
  }
}

AlertView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  msg: PropTypes.string,
  type: PropTypes.string,
  hideAlert: PropTypes.func.isRequired,

  // isOpen: PropTypes.bool,
  // title: PropTypes.string,
  // msg: PropTypes.string,
  // type: PropTypes.string,
  // hideAlert: PropTypes.func,
};

const mapStateToProps = ({ alert: { isOpen, title, msg, type } }) => ({
  isOpen,
  title,
  msg,
  type,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideAlert,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AlertView);
