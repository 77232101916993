import { lazy } from "react";

const Brands = lazy(() => import("./brands/Brands"));

const allRoutes = [
  {
    path: "/pages/brands",
    // path: "/dashboard/v1",
    component: Brands,
  },
];

export default allRoutes;
